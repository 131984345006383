import * as React from 'react';


enum LoadState {
    NOT_LOADED = 1,
    LOADING,
    LOADED
}


export interface RequestSpinnerProps /*extends DelayedSpinnerProps*/ {
    operation: () => Promise<any>;
    onComplete?: () => void;
}


export interface RequestSpinnerState {
    loadState: LoadState;
}


class RequestSpinner extends React.Component<RequestSpinnerProps, RequestSpinnerState> {

    private operationCancelled: boolean = false;

    constructor(props: RequestSpinnerProps) {
        super(props);
        this.state = {
            loadState: LoadState.NOT_LOADED
        };
    }

    public componentDidUpdate(): void {
        this.performOperation();
    }

    public componentDidMount(): void {
        this.performOperation();
    }

    public componentWillUnmount(): void {
        this.operationCancelled = true;
    }

    private performOperation = (): void => {
        if (this.state.loadState !== LoadState.NOT_LOADED)
            return;

        this.setState({
            loadState: LoadState.LOADING
        }, () => {
            if (!this.operationCancelled)
                this.props.operation().then(this.onOperationFinished).catch(this.onOperationError)
        });
    };

    private onOperationFinished = (): void => {
        if (this.operationCancelled)
            return;

        this.setState({
            loadState: LoadState.LOADED
        }, () => {
            if (this.props.onComplete)
                this.props.onComplete();
        })
    };

    private onOperationError = (): void => {
        if (this.operationCancelled)
            return;

        // TODO Handle this in a better way
        alert('An error occurred while retrieving data. Your login session may have expired. Try signing out and signing in again.');

        this.setState({
            loadState: LoadState.LOADED
        })
    };

    public render(): React.ReactNode {
        // noinspection JSUnusedLocalSymbols
        const {operation, ...props} = this.props;
        const loaded = (this.state.loadState === LoadState.LOADED);

        if (!loaded) {
            return (<div />); // TODO Add Spinner here
            //return (<DelayedSpinner {...props} />);
        } else {
            return this.props.children;
        }
    }
}


export default RequestSpinner;

import * as React from 'react';
import * as Intl from 'react-intl-universal';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Scaffold from "../../components/Scaffold/Scaffold";

import './ContactUs.scss';

class ContactUs extends React.Component<{}, {}> {

    public render(): React.ReactNode {

        return (
            <Scaffold header={(<Header />)} footer={(<Footer />)}>
                <main className="main-contact-us default-margins-x default-margins-y">
                    <h1> {Intl.getHTML('CONTACT_US.TITLE')} </h1>
                    {Intl.getHTML('CONTACT_US.PARAGRAPH_1')}
                </main>
            </Scaffold>
        );
    }
}

export default ContactUs;

import {AxiosRequestConfig, AxiosResponse} from "axios";
import {SERVER_DOMAIN} from "../Configuration";
import {getAxiosInstance} from "./config/ApiConfig";
import {IPasswordReset, IPasswordResetSubmission} from "./model/PasswordReset";


export function postPasswordResetSubmit(emailOrUsername: string): Promise<AxiosResponse<void>> {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url: '/api/v2/passwordReset/submit',
        data: {
            domainId: SERVER_DOMAIN,
            emailOrUsername
        } as IPasswordResetSubmission
    };

    return getAxiosInstance().request(config);
}


export function getPasswordResetTokenExists(token: string): Promise<AxiosResponse<void>> {
    const config: AxiosRequestConfig = {
        method: 'GET',
        url: `/api/v2/passwordReset/token/${token}`
    };

    return getAxiosInstance().request(config);
}


export function postPasswordReset(token: string, password: string): Promise<AxiosResponse<void>> {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url: `/api/v2/passwordReset/token/${token}/resetPassword`,
        data: {
            password
        } as IPasswordReset
    };

    return getAxiosInstance().request(config);
}

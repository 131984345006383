import * as React from 'react';
import * as Path from '../../Paths';
import * as Intl from 'react-intl-universal';

import logo from '../../img/ANDLogo.svg';

import './Footer.scss';


class Footer extends React.Component<{}, {}> {

    public render(): React.ReactNode {
        return (
            <footer className="main-footer">
                <div className="footer-col-left">
                    <a href={Intl.get('FOOTER.LOGO.IMG_LINK')}>
                        <img src={logo} alt={Intl.get('FOOTER.LOGO.IMG_ALT')}/>
                    </a>
                </div>
                <div className="footer-col-right">
                    <div className="footer-copyright">
                        {Intl.getHTML('FOOTER.COPYRIGHT.TEXT')}
                    </div>
                    <div className="footer-links">
                        <a {...Path.buildLinkAttributesFromPath(Path.SUPPORT)}>{Intl.get('FOOTER.SUPPORT.LINK')}</a>
                        <a {...Path.buildLinkAttributesFromPath(Path.PRIVACY_POLICY)}>{Intl.get('FOOTER.PRIVACY_POLICY.LINK')}</a>
                        <a {...Path.buildLinkAttributesFromPath(Path.CONTACT_US)}>{Intl.get('FOOTER.CONTACT_US.LINK')}</a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;

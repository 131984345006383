import Axios from 'axios';
// import * as moment from 'moment';

// !!!IMPORTANT!!! Any required moment locales must be imported here!
// import 'moment/locale/en-ca';
// import 'moment/locale/fr-ca';
// import 'moment/locale/es';


export const DEFAULT_LOCALE = 'en_CA';

export const SPHYGMO_LOCALES: {[key: string]: string} = {
    'en_CA': process.env.PUBLIC_URL + '/l10n/en_CA.json',
    'fr_CA': process.env.PUBLIC_URL + '/l10n/fr_CA.json',
    'es':    process.env.PUBLIC_URL + '/l10n/es.json',
};

export function localeExists(locale: string): boolean {
    return Object.keys(SPHYGMO_LOCALES).indexOf(locale) !== -1;
}

export async function loadLocaleData(locale: string): Promise<string> {
    const path = SPHYGMO_LOCALES[locale];
    const response = await Axios.get(path);
    return response.data;
}

export function formatLocaleWithDash(locale: string): string {
    return (locale == null) ? locale : locale.replace('_', '-');
}

export function formatLocaleWithUnderscore(locale: string): string {
    return (locale == null) ? locale : locale.replace('-', '_');
}

export function setMomentLocale(locale: string): boolean {
    // const targetLocale = formatLocaleWithDash(locale).toLowerCase();
    // const momentLocale = moment.locale(targetLocale).toLowerCase();
    // const momentLocaleChangedSuccessfully = (momentLocale === targetLocale);
    // if (!momentLocaleChangedSuccessfully)
    //     console.error(`Failed to change moment's locale. Wanted ${targetLocale} but got ${momentLocale}.`);
    //
    // return momentLocaleChangedSuccessfully;
    return true;
}

import * as React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Scaffold from "../../components/Scaffold/Scaffold";

import './PrivacyPolicy.scss';
import PrivacyPolicyContent from "./PrivacyPolicyContent";

class PrivacyPolicy extends React.Component<{}, {}> {

    public render(): React.ReactNode {

        return (
            <Scaffold header={(<Header />)} footer={(<Footer />)}>
                <main className="main-privacy-policy default-margins-x default-margins-y">
                    <h1>Privacy Policy</h1>
                    <PrivacyPolicyContent />
                </main>
            </Scaffold>
        );
    }
}

export default PrivacyPolicy;

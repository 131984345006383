import * as React from 'react';
import * as Path from '../../Paths';
import * as Intl from 'react-intl-universal';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Scaffold from "../../components/Scaffold/Scaffold";


import SpinnerButton from "../../components/SpinnerButton/SpinnerButton";
import './PasswordReset.scss';
import {AxiosError} from "axios";
import {parseAxiosError} from "../../api/model/SfygApiProblem";
import {getPasswordResetTokenExists, postPasswordReset} from "../../api/PasswordReset";
import RequestSpinner from "../../components/RequestSpinner/RequestSpinner";
import {WarningIcon} from "../../icons/Icons";


const PASSWORD_RESET_PASSWORD_MODE_IDLE = 0;
const PASSWORD_RESET_PASSWORD_MODE_BAD_TOKEN = 1;
const PASSWORD_RESET_PASSWORD_MODE_TOKEN_CHECK_FAILED = 2;
const PASSWORD_RESET_PASSWORD_MODE_PASSWORD_MISMATCH = 3;
const PASSWORD_RESET_PASSWORD_MODE_PASSWORD_INVALID = 4;
const PASSWORD_RESET_PASSWORD_MODE_SUBMITTING = 5;
const PASSWORD_RESET_PASSWORD_MODE_OK = 6;
const PASSWORD_RESET_PASSWORD_MODE_FAILED = 7;

type PasswordResetPasswordMode = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;


interface IPasswordResetPasswordProps {
    token: string;
}

interface IPasswordResetPasswordState {
    mode: PasswordResetPasswordMode;
    password1: string;
    password2: string;
}


class PasswordResetPassword extends React.Component<IPasswordResetPasswordProps, IPasswordResetPasswordState> {

    constructor(props: IPasswordResetPasswordProps) {
        super(props);
        this.state = {
            mode: PASSWORD_RESET_PASSWORD_MODE_IDLE,
            password1: '',
            password2: ''
        };
    }

    private onFormSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
    };

    private onLoad = async (): Promise<void> => {
        await getPasswordResetTokenExists(this.props.token).catch((error: AxiosError) => {
            const apiError = parseAxiosError(error);
            switch (apiError.sfygErrorCode) {
                case 2005: this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_BAD_TOKEN }); break;
                default:   this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_TOKEN_CHECK_FAILED }); break;
            }
        });
    };

    private onPreSubmit = (): boolean => {
        const {mode, password1, password2} = this.state;
        if (mode === PASSWORD_RESET_PASSWORD_MODE_SUBMITTING)
            return false;

        if (password1 !== password2) {
            this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_PASSWORD_MISMATCH });
            return false;
        }

        if ((password1.length < 8 || (password1.search(/[^\s\d]/) === -1) || (password1.search(/\d/) === -1))) {
            this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_PASSWORD_INVALID });
            return false;
        }

        return true;
    };

    private onSubmit = async (): Promise<void> => {
        this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_SUBMITTING });

        const response = await postPasswordReset(this.props.token, this.state.password1).catch((error: AxiosError) => {
            const apiError = parseAxiosError(error);
            switch (apiError.sfygErrorCode) {
                case 2003: this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_PASSWORD_INVALID }); break;
                case 2005: this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_BAD_TOKEN }); break;
                default:   this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_FAILED }); break;
            }

            return undefined;
        });

        if (response != null)
            this.setState({ mode: PASSWORD_RESET_PASSWORD_MODE_OK });
    };

    private renderAlert = (): React.ReactNode => {
        if (this.state.mode === PASSWORD_RESET_PASSWORD_MODE_BAD_TOKEN) {
            return (
                <div className="password-reset-alert password-reset-alert-error">
                    <h2> {Intl.get('PASSWORD_RESET.BAD_TOKEN.TITLE')} </h2>
                    {Intl.getHTML('PASSWORD_RESET.BAD_TOKEN.PARAGRAPH_1')}
                    {Intl.getHTML('PASSWORD_RESET.BAD_TOKEN.PARAGRAPH_2', { link: {...Path.buildLinkAttributesFromPath(Path.PASSWORD_RESET_SUBMIT)} })}
                    {/* <p>You can try resetting your password again <a {...Path.buildLinkAttributesFromPath(Path.PASSWORD_RESET_SUBMIT)}>here</a>.</p> */}
                </div>
            );
        }

        if (this.state.mode === PASSWORD_RESET_PASSWORD_MODE_TOKEN_CHECK_FAILED) {
            return (
                <div className="password-reset-alert password-reset-alert-error">
                    <h2> {Intl.get('PASSWORD_RESET.TOKEN_CHECK_FAILED.TITLE')} </h2>
                    {Intl.getHTML('PASSWORD_RESET.TOKEN_CHECK_FAILED.PARAGRAPH_1')}
                    {Intl.getHTML('PASSWORD_RESET.TOKEN_CHECK_FAILED.PARAGRAPH_2')}
                </div>
            );
        }

        if (this.state.mode === PASSWORD_RESET_PASSWORD_MODE_OK) {
            return (
                <div className="password-reset-alert password-reset-alert-success">
                    <h2> {Intl.get('PASSWORD_RESET.OK.TITLE')} </h2>
                    {Intl.getHTML('PASSWORD_RESET.OK.PARAGRAPH_1')}
                </div>
            );
        }

        return null;
    };

    private renderForm = (): React.ReactNode => {
        if (this.state.mode === PASSWORD_RESET_PASSWORD_MODE_BAD_TOKEN || this.state.mode === PASSWORD_RESET_PASSWORD_MODE_TOKEN_CHECK_FAILED || this.state.mode === PASSWORD_RESET_PASSWORD_MODE_OK)
            return null;

        const isInvalid = (this.state.mode === PASSWORD_RESET_PASSWORD_MODE_PASSWORD_MISMATCH) || (this.state.mode === PASSWORD_RESET_PASSWORD_MODE_PASSWORD_INVALID) || (this.state.mode === PASSWORD_RESET_PASSWORD_MODE_FAILED);
        const isDisabled = (this.state.mode === PASSWORD_RESET_PASSWORD_MODE_SUBMITTING);

        let alertMsg: string = '';
        switch (this.state.mode) {
            case PASSWORD_RESET_PASSWORD_MODE_PASSWORD_MISMATCH: alertMsg = Intl.get('PASSWORD_RESET.PASSWORD_MISMATCH.ERROR_MSG'); break;
            case PASSWORD_RESET_PASSWORD_MODE_PASSWORD_INVALID:  alertMsg = Intl.get('PASSWORD_RESET.PASSWORD_INVALID.ERROR_MSG'); break;
        }

        return (
            <div>
                {Intl.getHTML('PASSWORD_RESET.PARAGRAPH_1')}
                <form onSubmit={this.onFormSubmit}>
                    <div className="form-group" style={{marginBottom: '0.5rem'}}>
                        <label htmlFor="password-reset-password-1"> {Intl.get('PASSWORD_RESET.PASSWORD.LABEL')} </label>
                        <input type="password"
                               className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                               id="password-reset-password-1"
                               value={this.state.password1}
                               onChange={ (event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ password1: event.target.value }); }}
                               disabled={isDisabled}
                               required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password-reset-password-2"> {Intl.get('PASSWORD_RESET.CONFIRM_PASSWORD.LABEL')} </label>
                        <input type="password"
                               className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                               id="password-reset-password-2"
                               value={this.state.password2}
                               onChange={ (event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ password2: event.target.value }); }}
                               disabled={isDisabled}
                               required />
                    </div>
                    <div className={`invalid-password-reset-alert ${isInvalid ? 'visible' : ''}`}>
                        <WarningIcon size={24} title="Warning" disableTooltip />
                        <span>{alertMsg}</span>
                    </div>
                    <SpinnerButton className="password-reset-submit" type="submit"
                                   preOperation={this.onPreSubmit}
                                   operation={this.onSubmit}
                                   buttonLabel={Intl.get('PASSWORD_RESET.SIGN_IN.BTN.TEXT')} />
                </form>
            </div>
        )
    };

    private renderContent = (): React.ReactNode => {
        return (
            <>
                {this.renderAlert()}
                {this.renderForm()}
            </>
        );
    };

    public render(): React.ReactNode {
        return (
            <Scaffold header={(<Header />)} footer={(<Footer />)}>
                <main className="main-password-reset default-margins-x default-margins-y">
                    <h1> {Intl.get('PASSWORD_RESET.TITLE')} </h1>
                    <RequestSpinner operation={this.onLoad}>
                        {this.renderContent()}
                    </RequestSpinner>
                </main>
            </Scaffold>
        );
    }
}


export default PasswordResetPassword;

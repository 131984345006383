import * as Intl from 'react-intl-universal';

import {AxiosError} from "axios";

export interface ISfygApiProblem {
    type?: string;
    title?: string;
    status?: number;
    detail?: string;
    instance?: string;
    sfygErrorCode?: number;
}

export function parseAxiosError(error: AxiosError): ISfygApiProblem {

    // The request was made and the server responded with a status code that falls out of the range of 2xx.
    if (error.response) {
        return error.response.data as ISfygApiProblem;
    }

    // The request was made but no response was received (`error.request` is an instance of XMLHttpRequest).
    if (error.request) {
        return {
            status: 0,
            sfygErrorCode: 0,
            title: Intl.get('API.ERROR.NETWORK.TITLE'),
            detail: Intl.get('API.ERROR.NETWORK.NO_RESPONSE')
        }
    }

    // Otherwise, something happened in setting up the request that triggered an Error

    return {
        status: 0,
        sfygErrorCode: 0,
        title: Intl.get('API.ERROR.NETWORK.TITLE'),
        detail: Intl.get('API.ERROR.NETWORK.APPLICATION_ERROR')
    }
}

import * as React from 'react';
import './Icons.scss';

// An incrementing counter that is used to sequentially ID icons as they are used.
let iconId: number = 0;

export interface IIconProps {
	// The width and height of the icon in pixels.
	size: number;

	// The title to assign to the SVG. It is usually displayed by the browser as a tooltip, and is also used for
	// ARIA support.
	title: string;

	// If true, sets pointer-events: none in the CSS so that no tooltip is shown from the SVG title when moused over.
	disableTooltip?: boolean;

	// Additional classes to apply to the container div.
	divClass?: string;

	// Additional classes to apply to the SVG element.
	svgClass?: string;
}

const SvgIcon: React.FunctionComponent<IIconProps & { iconClass: string }> = props => {
    const id = `sfyg-svg-icon-i${++iconId}`;
    const pointerEvents = props.disableTooltip ? "none" : "auto";
    const divClass = `sfyg-svg-icon ${props.divClass || ""}`;
    const svgClass = `${props.iconClass} ${props.svgClass || ""}`;
    return (
        <div className={divClass} style={{ width: props.size, height: props.size, pointerEvents }}>
            <div className="sfyg-svg-icon-positioning">
                <svg xmlns="http://www.w3.org/2000/svg"
                     className={svgClass}
                     viewBox="0 0 320 320"
                     preserveAspectRatio="xMidYMid meet"
                     role="img"
                     aria-labelledby={id}>
                    <title id={id}>{props.title}</title>
                    {props.children}
                </svg>
            </div>
        </div>
    );
};

export const WarningIcon: React.FunctionComponent<IIconProps> = props => (
    <SvgIcon iconClass="sfyg-svg-icon-warning" {...props}>
        <path d="M160.4 30.6A7.6 8.5 0 0 0 153.5 34.8L28.7 276.7A7.6 8.5 0 0 0 35.2 289.4L284.8 289.4A7.6 8.5 0 0 0 291.3 276.7L166.5 34.8A7.6 8.5 0 0 0 160.4 30.6zM160 56L271.7 272.5 48.3 272.5 160 56zM148 115.5L152 220.5 168 220.5 172 115.5 148 115.5zM149.3 235.1L149.3 256.5 170.7 256.5 170.7 235.1 149.3 235.1z" />
    </SvgIcon>
);

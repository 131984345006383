import * as React from 'react';
import * as Path from '../../Paths';
import * as Intl from 'react-intl-universal';
import {navigateToPath} from "../../util/NavigationUtils";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Scaffold from "../../components/Scaffold/Scaffold";

import './Support.scss';


class Support extends React.Component<{}, {}> {

    private onResetPassword = (): void => {
        navigateToPath(Path.PASSWORD_RESET_SUBMIT);
    };

    public render(): React.ReactNode {
        return (
            <Scaffold header={(<Header />)} footer={(<Footer />)}>
                <main className="main-support default-margins-x default-margins-y">
                    <h1> {Intl.getHTML('SUPPORT.TITLE')} </h1>
                    {Intl.getHTML('SUPPORT.PARAGRAPH_1')}
                    {/*<h3>{Intl.getHTML('SUPPORT.TITLE.H3')}</h3>*/}
                    {/*{Intl.getHTML('SUPPORT.PARAGRAPH_2')}*/}
                    {/*<button className="password-reset-btn" onClick={this.onResetPassword}>{Intl.getHTML('SUPPORT.PASSWORD_RESET.BTN.TEXT')}</button>*/}
                </main>
            </Scaffold>
        );
    }
}

export default Support;

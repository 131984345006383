import * as React from 'react';

class PrivacyPolicyContent extends React.Component<{}, {}> {

    public render(): React.ReactNode {

        return (
            <div className="privacy-policy-content">
                <h2>Sphygmo Mobile Application Privacy Notice</h2>
                <p>Version Date: March 2019</p>

                <h3>Introduction</h3>

                <p><b>Please read this Privacy Notice (the “Privacy Notice”) for the Sphygmo Home App carefully before accepting it. Acceptance of this Privacy Notice will transmit your personal information, including health-related information, into a Sphygmo Home App account which stores information in the cloud within your local region.</b> BY ACCEPTING THIS PRIVACY NOTICE AND USING THE SPHYGMO HOME APP, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ACCEPT THIS PRIVACY NOTICE, AND THAT YOU ARE AGREEING EITHER ON YOUR OWN BEHALF, OR ON BEHALF OF ANOTHER INDIVIDUAL FOR WHOM YOU HAVE ACTUAL AUTHORITY TO LEGALLY ACCEPT TO THIS PRIVACY NOTICE.</p>
                <p>mmHg Inc., of 5-134, 11350 83 Ave, Clinical Sciences Building, University of Alberta, Edmonton AB, T6G 2G3, Canada, is the controller of personal information transmitted through the Sphygmo Home App to your Sphygmo Home App account. You may contact us at the address above or at app-support@mmhg.ca.</p>
                <p>References to “<b>you</b>,” “<b>your</b>,” or the “<b>user</b>” in this Privacy Notice are references to the individual using the Sphygmo Home App. References to “<b>mmHg</b>,” “<b>us</b>,” “<b>our</b>,” or “<b>we</b>” in this Privacy Notice are to mmHg Inc.</p>
                <p>mmHg recognizes the importance of data protection and privacy and is committed to protecting your personal information, including health-related information.</p>
                <p><b>This Privacy Notice explains how we handle personal information</b> including health-related information that you provide to us through your use of the Sphygmo Home App. This Privacy Notice does not apply to personal information collected by mmHg (including its subsidiaries and affiliates) via other methods, such as web sites, customer call centers, or Sphygmo Home App software; nor does this Privacy Notice apply to other third-party web sites.</p>

                <p><b>For European Economic Area (“EEA”) And Swiss Users:</b> To process your personal data concerning health, <b>you must provide to mmHg affirmative consent</b> to use the Sphygmo Home App. <b>You may withdraw your consent at any time</b> by first deleting the Sphygmo Home App from your device and then logging into your Sphygmo Home App account via sphygmobp.com and using the delete account functionality. For more information about the other legal bases relied upon by mmHg for processing personal data uploaded through the Sphygmo Home App, please refer to the section entitled “What information does this Privacy Notice govern?” below.</p>
                <p><b>YOUR USE OF THE SPHYGMO HOME APP CONSTITUTES, AND IS CONDITIONED UPON, YOUR ACCEPTANCE OF THIS PRIVACY NOTICE. IN CERTAIN EXCEPTIONAL CIRCUMSTANCES, MMHG’S DATA PROCESSORS MAY NEED TO ACCESS YOUR PERSONAL INFORMATION, INCLUDING HEALTH-RELATED INFORMATION. BY ACCEPTING, YOU EXPLICITLY ACKNOWLEDGE THAT THIS PRIVACY NOTICE WILL APPLY TO YOUR USE OF THE SPHYGMO HOME APP AND TO THE PROCESSING AND TRANSFER OF YOUR PERSONAL INFORMATION, INCLUDING HEALTH-RELATED INFORMATION, AS SET OUT IN THIS PRIVACY NOTICE.</b></p>
                <p>The index and headings in this Privacy Notice are for your convenience only, and do not limit, define, or fully explain each section.</p>

                <div>1. Background of the Sphygmo Home App.<br />
                    2. No medical advice.<br />
                    3. Unauthorized third party products.<br />
                    4. What does this Privacy Notice apply to?<br />
                    5. What information does this Privacy Notice govern?<br />
                    6. How is personal information handled?<br />
                    7. How long does mmHg store personal information?<br />
                    8. Does mmHg share personal information with third parties?<br />
                    9. How does mmHg secure personal information?<br />
                    10. Where does mmHg store and transfer personal information?<br />
                    11. Will mmHg send me marketing material?<br />
                    12. How does mmHg protect the privacy of children?<br />
                    13. How may I access and/or correct my personal information?<br />
                    14. What rights do I have over my personal information?<br />
                    15. How do I delete my Sphygmo Home App account?<br />
                    16. How can I contact mmHg?<br />
                    17. Changes to this Privacy Notice.<br />
                </div>

                <h3>1. Background of the Sphygmo Home App.</h3>

                <p>mmHg developed the Sphygmo Home App (“App”) and holds the marketing authorizations/registrations for it.</p>
                <p>You can use the Sphygmo Home App to collect data from authorized blood pressure measurement devices with your mobile device, which connects with the blood pressure measurement devices via Bluetooth, or by manual entry of authorized blood pressure measurement device readings by the user. The Sphygmo Home App will then use that collected data to display your blood pressure measurement values and calculate averages using this data, which will be uploaded to and stored in your Sphygmo Home App account. The Sphygmo Home App is a secure, cloud-based blood pressure management system that may be used by individuals, and mmHg to aid in the review, analysis, and evaluation of historical blood pressure data, and user-entered information, including blood pressure, weight, height, basic clinical medical history, and current medications, to support an effective blood pressure health management program. The Sphygmo Home App enables mmHg to improve the quality, security, and effectiveness of medical devices and systems and allow mmHg to develop innovative and effective treatment for and management of blood pressure in the interests of public health. Use of the Sphygmo Home App may require: compatible devices, internet access, data usage (charges may apply), certain software (fees may apply), and periodic updates, and the performance of the Sphygmo Home App may be affected by these requirements.</p>
                <p><b>For EEA and Swiss Users:</b> mmHg is the controller of your personal information, including health-related information within the Sphygmo Home App.</p>
                <p><b>mmHg, Inc.</b> holds the marketing authorizations/registrations for the Sphygmo Home App, and provides the system to you as a data processor.</p>

                <h3>2. No medical advice.</h3>

                <p>THE SPHYGMO HOME APP IS NOT INTENDED FOR THE DIAGNOSIS OF OR SCREENING FOR HYPERTENSION. USERS SHOULD BE AWARE THAT THE SPHYGMO HOME APP IS AN INFORMATION MANAGEMENT SERVICE TO ENABLE THE ANALYSIS OF BLOOD PRESSURE DATA AND IS NOT INTENDED TO BE A SUBSTITUTE FOR THE ADVICE OF A HEALTH CARE PROFESSIONAL. INDIVIDUALS SHOULD ALWAYS CONSULT THEIR DOCTOR OR OTHER QUALIFIED HEALTH CARE PROFESSIONAL WITH ANY QUESTIONS THEY MAY HAVE REGARDING A MEDICAL CONDITION, INCLUDING ANY QUERIES OR CONCERNS ABOUT BLOOD PRESSURE MANAGEMENT. YOU SHOULD NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN SEEKING IT BECAUSE OF INFORMATION TRANSMITTED TO OR CONTAINED IN YOUR SPHYGMO HOME APP ACCOUNT. You should follow customary guidelines set by Your doctor or other healthcare professional(s) when Your readings are too high or low or if You experience other blood pressure-related symptoms in Your medical condition.</p>
                <p><b>MMHG IS NOT A PROVIDER OF MEDICAL CARE AND MMHG IS NOT RESPONSIBLE FOR NOTIFYING AN INDIVIDUAL’S DOCTOR OR OTHER HEALTHCARE PROFESSIONAL(S) OF ANY CHANGES IN YOUR READINGS.</b></p>
                <p>YOUR DECISION TO TAKE ACTION BASED ON ANY INFORMATION TRANSMITTED TO OR STORED ON THE SPHYGMO HOME APP OR ON ANY INFORMATION RECEIVED FROM MMHG EMPLOYEES, AGENTS, OR SUPPLIERS IS SOLELY AT YOUR OWN RISK.</p>

                <h3>3. Unauthorized third party products.</h3>

                <p>Please be aware that third parties may offer services, software, mobile apps, and blood pressure devices not authorized by mmHg and which they claim may be compatible with the Sphygmo Home App, and/or the Sphygmo Home App. mmHg does not recommend using the Sphygmo Home App with these unauthorized third party products, and is not responsible if you do. If you do use these third-party products or services, your use is subject to their respective privacy policies and terms of use. Where you do use such third-party products, services, software or apps and upload information in your Sphygmo Home App account, mmHg will receive and process such data, including if you have uploaded health-related information.</p>

                <h3>4. What does this Privacy Notice apply to?</h3>

                <p>This Privacy Notice applies only to the personal information you submit through the Sphygmo Home App when creating an account in the Sphygmo Home App and to the personal information, including health-related information, that we may receive from you or others (as explained in this notice) and stored within the Sphygmo Home App. Data will be uploaded to your Sphygmo Home App account when you use the Sphygmo Home App to connect with your blood pressure device, or if data is entered manually, and through any customer services we provide that are connected to your use of the Sphygmo Home App. You may also be able to access your account for the Sphygmo blood pressure management service via a website domain, depending on your country of residence. This Privacy Notice does not apply to any other personal information provided by you or collected from you by mmHg.</p>

                <h3>5. What information does this Privacy Notice govern?</h3>

                <p>This Privacy Notice covers the following information:</p>

                <ul>
                    <li>The Sphygmo Home App account profile that you complete and which includes the name, date of birth, country, and email address of adult users for your own use or for use by another adult (where you have authority to do so), and in the case of pediatric use, in addition to the email address of the parent/guardian, the child’s name, date of birth and country.</li>
                    <li>Health-related information sent from your authorized blood pressure device and manually entered by You, such as how often you use your blood pressure device, your use your blood pressure device, blood pressure targets, blood pressure values, clinical medical history, weight, and height.</li>
                    <li>Information we receive about your mobile device, including information about your operating system and other troubleshooting and analytical data to help us provide the Sphygmo Home App to you.</li>
                </ul>

                <p>Depending on your country of residence and/or where approved, the Sphygmo Home App may offer additional functionality and features, which may allow you to upload additional personal information to your account in the Sphygmo Home App, such as:</p>

                <ul>
                    <li>The Sphygmo Home App may permit you to connect to or link with other third party apps. If you choose to make such app connections, you may upload information from the third-party app into your Sphygmo Home App account, and we will process that information in the same we way we process all other information you upload to your Sphygmo Home App account via the Sphygmo Home App and as set out in this Privacy Notice. Connecting or linking the Sphygmo Home App to a third-party partner of mmHg will require us to pass your personal information to that third-party partner to permit the connected app/linking functionality. Where you connect or link your Sphygmo Home App with other third party products or services, mmHg may receive information from those third parties. Any use by you of those third-party apps will be subject to their respective terms and conditions and privacy policy.</li>
                    <li>If you use the Sphygmo Home App website, you may also be able to input information from your other blood pressure measurement devices.</li>
                </ul>

                <p>The Sphygmo Home App transmits personal information and data directly to the Sphygmo Home App account pursuant to this Privacy Notice. You may manage how your Sphygmo Home App interacts with your Sphygmo Home App account by accessing the Sphygmo Home App settings. You may also use the Sphygmo Home App to change your account settings.</p>

                <h3>6. How is personal information handled?</h3>

                <p>When you register for a Sphygmo Home App account, registration data (name, date of birth, email address) are kept separate from the information you upload from your App. mmHg uses technical and administrative measures to ensure data separation and will never combine these data.</p>

                <h5>Personal information</h5>

                <p>mmHg uses personal information, including health-related information and data derived from your blood pressure device and your mobile device in the following ways:</p>

                <ul>
                    <li>to provide you with a Sphygmo Home App account to give you access to and present your information, including health-related information, in an easy-to-use and effective manner, to allow you to store, back-up and retrieve your historic blood pressure values, and to ensure continuous access to information about how you manage your blood pressure in case your mobile device is lost or stolen.</li>
                    <li>to help us fix any issues with the Sphygmo Home App, including where we respond to your questions or respond to your request for support, troubleshooting, or other any performance issues.</li>
                    <li>where you opted-in, we may prepare and send you tailored marketing information, based on the data uploaded (including health-related information) or connected with your Sphygmo Home App, including from your local mmHg company. You will be provided with an opportunity to opt in to these communications, if they are available, when you first provide us with your personal information. If you initially opt-in, you will also be provided an opportunity to opt-out through each marketing communication you receive from us.</li>
                    <li>if you are a parent or guardian, to enable you to create a Sphygmo Home App account for use by your child, and to allow you to manage their use of the Sphygmo Home App account.</li>
                    <li><b>For United States of America Users only:</b> to provide improved treatment guidance for you and healthcare professionals using the Sphygmo Home App, as well as for research and mmHg’s health care operations activities, as applicable as described in this Privacy Notice and our HIPAA or PIPEDA Notice of Privacy Practices.<br /></li>
                </ul>

                <h5>Data Analysis</h5>

                <p>mmHg uses de-identified, pseudonymized, aggregated, and/or anonymized information for limited purposes. mmHg performs tasks as a data processor with this data analytics process, in particular, the processes related to de-identifying, pseudonymizing and/or anonymized information. This is information which mmHg securely holds and will not be used to identify you individually by your name or email address. The purposes for which mmHg will use this information are:</p>

                <ul>
                    <li>to improve the quality, security and effectiveness of health devices and systems and to allow for the development of innovative and effective treatment for and management of blood pressure and other cardiovascular diseases in the interests of public health.</li>
                    <li>to create, access, retain, use, and disclose to our affiliated companies and to third-party researchers, healthcare entities or professionals or public health authorities for the purposes of scientific research, statistical purposes and analysis.</li>
                    <li>to validate upgrades, and to keep the Sphygmo Home App safe and secure.<br /></li>
                </ul>

                <h3>7. How long does mmHg store personal information?</h3>

                <p>mmHg will continue to store your personal information while you have an active Sphygmo Home App account and in accordance with applicable data retention requirements. The section entitled “How do I delete my Sphygmo Home App account” below explains how you can delete your account and what happens to your personal information once your account has been deleted.</p>

                <h3>8. Does mmHg share personal information with third parties?</h3>

                <p>We share personal information with our third-party suppliers solely to provide, maintain, host, and support the Sphygmo Home App. mmHg, which holds the marketing authorizations/registrations for the Sphygmo Home App, will process personal information, including health-related information. mmHg acts as a data processor with the data analytics process, in particular, the processes related to de-identifying, pseudonymizing and/or anonymized information. Where we provide your personal information to third-party suppliers to assist us with the provision of your Sphygmo Home App account, they are required to keep your personal information confidential and secure and to use your personal information to the minimum extent necessary.</p>
                <p>mmHg may use third-party service providers to provide you with the Sphygmo Home App. For example, we may use third-party service providers to report when the Sphygmo Home App crashes or experiences certain errors so that we can support and improve the Sphygmo Home App, and when these crashes or errors occur, the Sphygmo Home App will send certain information about the incident to such third parties. The information sent to such third parties will not involve the use of your personal information.</p>
                <p>Where you opt-in to receive direct marketing communications from us, we may share your personal information with local affiliated mmHg companies with whom we are jointly marketing a product or service or jointly conducting a program or activity. This will only occur where you consent to sharing, for example, where you opt in to receive marketing communications from mmHg (or its affiliates) as described in this Privacy Notice. We also may share your personal information with third parties where you have expressly asked us to do so. We will not sell or license your personal information to third parties except in connection with the sale, merger, or transfer of a product line or division, so that the buyer can continue to provide you with information and services. For the avoidance of doubt, we will never sell your personal information for commercial purposes to third parties and we may only share your personal information with third parties where you have provided consent or where permitted by applicable law.</p>
                <p>We may share and You hereby consent to us sharing such de-identified, pseudonymized, aggregated, and/or anonymized information with affiliated mmHg companies and with other third parties for the purposes relating to “Data Analysis” set out above. This is information which mmHg securely holds and will not be used to identify you individually by your name or email address.</p>
                <p>We reserve the right to disclose your personal information to respond to authorized information requests from government authorities, to address national security situations, or when otherwise required by law. Furthermore, where permitted or required by law, we may also disclose the information we collect from you where we believe it is necessary to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of this Privacy Notice, or as evidence in litigation in which we are involved. Your personal information may be subject to foreign laws and may be accessible by foreign governments, courts, law enforcement, and regulatory agencies.</p>

                <h3>9. How does mmHg secure personal information?</h3>

                <p>We have implemented administrative, technical, and physical safeguards to protect your personal information, including health-related information, from accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access, including through the use of cryptographic technologies. mmHg restricts access to personal information by its employees on a need to know basis. Please keep in mind that no internet or Wi-Fi transmission is 100% secure, so please exercise caution when uploading personal information, especially health-related information, to your Sphygmo Home App account.</p>
                <p>You are responsible for protecting against unauthorized access to your devices and your Sphygmo Home App account. We recommend securing access to your mobile device by locking your mobile device, choosing a robust password that nobody else knows or can easily guess, and keeping your account information and password private. mmHg is not responsible for any lost, stolen, or compromised passwords or for any activity on your Sphygmo Home App account from unauthorized users where caused by you. If you think your Sphygmo Home App account has been compromised, please contact us as soon as you are able at app-support@mmhg.ca.</p>

                <h3>10. Where does mmHg store and transfer personal information?</h3>

                <p>The personal information transmitted to the Sphygmo Home App will be stored in the cloud on secure servers located in <b><u>Canada.</u></b></p>

                <p>When your personal information is hosted in a country other than the country you selected, it may become subject to the laws of the host country, which may not be equivalent to the laws of the country you selected. However, mmHg will put appropriate measures in place to protect your personal information and ensure that your personal information only be collected, used, and disclosed as permitted under applicable laws.</p>

                <p><b>BY CREATING A SPHYGMO HOME APP ACCOUNT AND BY ACKNOWLEDGING THIS PRIVACY NOTICE, WE ARE INFORMING YOU OF THESE TRANSFERS OF YOUR PERSONAL INFORMATION TO CANADA, AND TO THE ACCESS OF YOUR PERSONAL INFORMATION FROM CANADA, INCLUDING HEALTH-RELATED INFORMATION, WHICH MAY BE REQUIRED IN EXCEPTIONAL CIRCUMSTANCES TO RESPOND TO ANY SUPPORT REQUESTS YOU SUBMIT. CANADA MAY NOT OFFER AN EQUIVALENT LEVEL OF PROTECTION FOR YOUR PERSONAL DATA WHEN COMPARED TO THE EEA COUNTRY IN WHICH YOU ARE LOCATED.</b></p>

                <h3>11. Will mmHg send me marketing material?</h3>

                <p>mmHg (or its affiliates) may send you advertising and marketing-related information about blood pressure management or their other products and services if (where required by law) you opted-in to receive such communications when you set up your Sphygmo Home App account profile. You may receive marketing information which is tailored to your specific needs based on the information uploaded or connected to your Sphygmo Home App account (including health-related information). We may also invite you to participate in surveys about our products, provide you with news and newsletters, or to notify you about special offers and promotions. You may opt out from receiving marketing-related communications by either clicking on the unsubscribe link at the bottom of marketing-related emails we send you, by changing your Sphygmo Home App preferences, or by contacting us at app-support@mmhg.ca. We will process opt-out requests without undue delay.</p>
                <p>Neither mmHg nor its affiliates or licensors will send advertising or marketing-related information to children.</p>
                <p>mmHg will not sell your personal information to third parties for direct marketing.</p>
                <p>Where you opt out of receiving marketing-related information about blood pressure management, we may continue to send you non-marketing related information. This information may be in relation to necessary system and service updates or issues including product safety.</p>
                <p><b>For United States of America Users:</b> Please be aware that when you share your personal information through the Sphygmo Home App and you opt-in to receive marketing communications from mmHg, you are authorizing mmHg to use and disclose your personal information so that mmHg may send you advertising and marketing-related information about blood pressure management or their other products and services. You understand that once your information is disclosed pursuant to this authorization that it may be re-disclosed and no longer protected by HIPAA. This authorization will remain in effect for so long as you share personal information with your healthcare provider through the Sphygmo Home App account and opt-in to receive marketing communications. You understand that you may revoke this authorization at any time by opting out of receiving marketing communications by either clicking on the unsubscribe link at the bottom of marketing-related emails we send you or by contacting us at app-support@mmhg.ca, but that this revocation will only apply to the extent that we have not already taken action based on it.</p>

                <h3>12. How does mmHg protect the privacy of children?</h3>

                <p>Each user is required to enter his or her date of birth and country so that we may establish whether the user is a child under the law of the user’s country. If a date of birth attributable to a child is entered, the user will be required to enter the email address of a parent/guardian before a Sphygmo Home App account will be created. The parent/guardian must consent to the child’s use of the Sphygmo Home App. Upon such consent, a Sphygmo Home App account will be created for the parent/guardian for use by a child.</p>
                <p>At any time, a parent/guardian may stop the collection of a child’s personal information, including health-related information, by requesting that mmHg delete the Sphygmo Home App account used by a child by contacting us at app-support@mmhg.ca. This action will delete the account being used by the child but we retain aggregated and de-identified information and may need to retain certain personal information as required by law.</p>

                <h3>13. How may I access and/or correct my personal information?</h3>

                <p>You may correct your profile information through the Sphygmo Home App account settings within the Sphygmo Home App. We are not able to correct/amend blood pressure device readings stored in the Sphygmo Home App account, but will assist you with deleting your blood pressure readings so that you can continue to collect correct information.</p>

                <h3>14. What rights do I have over my personal information?</h3>

                <p>Depending on your place of residence, you may have the right to: (a) access the personal information we hold about you; (b) request we correct any inaccurate personal information we hold about you; (c) delete any personal information we hold about you; (d) restrict the processing of personal information we hold about you; (e) object to the processing of personal information we hold about you; and/or (f) receive any personal information you have provided to us on the basis of your consent, in a structured and commonly used machine-readable format or have such personal information transmitted to another company.</p>
                <p>Children may also have the right to access the personal information held about them through the Sphygmo Home App. Where we receive a request for access for a child’s personal information from the child’s parent/guardian, we may respond directly to the child’s parent/guardian. We will always seek to verify the identity of the person seeking access to a child’s information, whether it is from the child him/herself or from a parent or guardian.</p>
                <p>To request the exercise of these rights, please contact us at app-support@mmhg.ca.</p>
                <p><b>For EEA and Swiss Users:</b> You have the right to lodge a complaint with your local data protection authority if you are unhappy with any aspect of mmHg’s processing of your personal information. </p>

                <h3>15. How do I delete my Sphygmo Home App account?</h3>

                <p>If you would like to delete your Sphygmo Home App account, please contact us at app-support@mmhg.ca. Please be aware that if you delete your account, we will retain aggregated and de-identified information and may need to retain certain personal information as required by law.</p>
                <p>Once your Sphygmo Home App account and any associated personal information has been deleted, you will no longer have access to the Sphygmo Home App and deletion of your account is irreversible. You may not therefore be able to reactivate your Sphygmo Home App account or retrieve any personal information, including health related information, so you may want to download and save any required information before requesting that we delete your account from the Sphygmo Home App.</p>
                <p>mmHg reserves the right to delete inactive Sphygmo Home App accounts after six (6) months. We attempt to notify you in advance so that you have an opportunity to ensure your account stays current and available for your use.</p>

                <h3>16. How can I contact mmHg?</h3>

                <p>If you have questions, comments, or complaints about our privacy practices, please contact us by clicking on the “Contact Us” link on one of our web sites, or emailing us at app-support@mmhg.ca. Alternatively, you may send a letter to the following address:</p>

                <p>Attn: Privacy Officer<br />
                    MmHg Inc.<br />
                    5-134 Clinical Sciences Building, 11350 83 Ave<br />
                    University of Alberta<br />
                    Edmonton, AB T6G 2G3<br />
                    Canada</p>

                <p>In all communications to us, please include the email address used to create your Sphygmo Home App account and a detailed explanation of your request.</p>

                <h3>17. Changes to this Privacy Notice.</h3>

                <p>If we change our privacy practices, an updated version of this Privacy Notice will reflect those changes. You will be alerted to updates to this Privacy Notice by email or via the Sphygmo Home App. You will be notified if there is a new version of this Privacy Notice and will be prompted to read and accept it so that you can continue to access and use your Sphygmo Home App account via the Sphygmo Home App. Without prejudice to your rights under applicable law, we reserve the right to update and amend this Privacy Notice without prior notice to reflect technological advancements, legal and regulatory changes and good business practices to the extent that it does not change the privacy practices as set out in this Privacy Notice.</p>
                <p>If you do not agree to the changes to the Privacy Notice, you should delete the Sphygmo Home App from your device, log into your Sphygmo Home App account via sphygmobp.com, and use the delete account functionality. Alternatively, you can request deletion of your Sphygmo Home App account by contacting us at app-support@mmhg.ca.</p>

                <p>Doc ID: V1.1 Sphygmo Home App Privacy Policy</p>
            </div>
        );
    }
}

export default PrivacyPolicyContent;

import * as React from 'react';
import * as Intl from "react-intl-universal";

import sfygSystem from "../../../img/sfyg-system.png";
import andhearttrackLogo from '../../../img/andhearttrack-logo.svg';

import './Hero.scss';

export interface IHeroProps {
    onLearnMore: () => void;
}

class Hero extends React.Component<IHeroProps, {}> {

    public render(): React.ReactNode {
        return (
            <div className="main-hero">
                <div className="main-hero-inner default-margins-x">
                    <div className="main-hero-col-left">
                        <img src={andhearttrackLogo}
                             alt={Intl.get('MAIN.HERO.LOGO_IMG.ALT')} />
                        <p>
                            {Intl.get('MAIN.HERO.DESCRIPTION')}
                        </p>
                        <button onClick={this.props.onLearnMore}>
                            {Intl.get('MAIN.HERO.LEARN_MORE_BTN_LABEL')}
                        </button>
                    </div>
                    <div className="main-hero-col-right">
                        <img src={sfygSystem}
                             alt="" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Hero;

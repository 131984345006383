import * as React from 'react';
import './Spinner.scss';

class Spinner extends React.Component<{}, {}> {

    public render(): React.ReactNode {
        return (
            <div className="spinner">
                <div className="lds-css ng-scope">
                    <div className="lds-dual-ring m-auto">
                        <div></div>
                        <div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Spinner;

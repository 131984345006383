import Axios, { AxiosInstance } from "axios";
import {AUTH_SERVER_BASE_URL} from "../../Configuration";


const axiosInstance = Axios.create({
  baseURL: AUTH_SERVER_BASE_URL,
  timeout: 10000,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
});


export function getAxiosInstance(): AxiosInstance {
  return axiosInstance;
}

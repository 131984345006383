import * as React from 'react';
import * as Intl from 'react-intl-universal';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Scaffold from "../../components/Scaffold/Scaffold";

import SpinnerButton from "../../components/SpinnerButton/SpinnerButton";

import {AxiosError} from "axios";
import {WarningIcon} from "../../icons/Icons";
import {postPasswordResetSubmit} from "../../api/PasswordReset";

import './PasswordReset.scss';


const PASSWORD_RESET_SUBMIT_MODE_IDLE = 0;
const PASSWORD_RESET_SUBMIT_MODE_SUBMITTING = 1;
const PASSWORD_RESET_SUBMIT_MODE_FORM_INVALID = 2;
const PASSWORD_RESET_SUBMIT_MODE_OK = 3;
const PASSWORD_RESET_SUBMIT_MODE_FAILED = 4;

type PasswordResetSubmitMode = 0 | 1 | 2 | 3 | 4;


interface IPasswordResetSubmitState {
    mode: PasswordResetSubmitMode;
    username: string;
}


class PasswordResetSubmit extends React.Component<{}, IPasswordResetSubmitState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            mode: PASSWORD_RESET_SUBMIT_MODE_IDLE,
            username: ''
        };
    }

    private onFormSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
    };

    private onPreSubmit = (): boolean => {
        const {mode, username} = this.state;
        if (mode === PASSWORD_RESET_SUBMIT_MODE_SUBMITTING)
            return false;

        if (username == null || username.length === 0) {
            this.setState({ mode: PASSWORD_RESET_SUBMIT_MODE_FORM_INVALID });
            return false;
        }

        return true;
    };

    private onSubmit = async (): Promise<void> => {
        this.setState({ mode: PASSWORD_RESET_SUBMIT_MODE_SUBMITTING });

        const response = await postPasswordResetSubmit(this.state.username).catch((error: AxiosError) => {
            this.setState({ mode: PASSWORD_RESET_SUBMIT_MODE_FAILED });
            return undefined;
        });

        if (response)
            this.setState({ mode: PASSWORD_RESET_SUBMIT_MODE_OK });
    };

    private renderAlert = (): React.ReactNode => {
        if (this.state.mode !== PASSWORD_RESET_SUBMIT_MODE_OK)
            return null;

        return (
            <div className="password-reset-alert password-reset-alert-success">
                <h2> {Intl.get('PASSWORD_RESET_SUBMIT.FORM_SUBMITTED.TITLE')} </h2>
                {Intl.getHTML('PASSWORD_RESET_SUBMIT.FORM_SUBMITTED.PARAGRAPH_1')}
                {Intl.getHTML('PASSWORD_RESET_SUBMIT.FORM_SUBMITTED.PARAGRAPH_2')}
                {Intl.getHTML('PASSWORD_RESET_SUBMIT.FORM_SUBMITTED.PARAGRAPH_3')}
            </div>
        );
    };

    private renderForm = (): React.ReactNode => {
        if (this.state.mode === PASSWORD_RESET_SUBMIT_MODE_OK)
            return null;

        const isInvalid = (this.state.mode === PASSWORD_RESET_SUBMIT_MODE_FORM_INVALID) || (this.state.mode === PASSWORD_RESET_SUBMIT_MODE_FAILED);
        const isDisabled = (this.state.mode === PASSWORD_RESET_SUBMIT_MODE_SUBMITTING);

        let alertMsg: string = '';
        switch (this.state.mode) {
            case PASSWORD_RESET_SUBMIT_MODE_FORM_INVALID: alertMsg = Intl.get('PASSWORD_RESET_SUBMIT.FORM_INVALID.ERROR_MSG'); break;
            case PASSWORD_RESET_SUBMIT_MODE_FAILED:       alertMsg = Intl.get('PASSWORD_RESET_SUBMIT.FORM_FAILED.ERROR_MSG'); break;
        }

        return (
          <>
              {Intl.getHTML('PASSWORD_RESET_SUBMIT.PARAGRAPH_1')}
              <form onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                      <label htmlFor="password-reset-username"> {Intl.get('PASSWORD_RESET_SUBMIT.USERNAME.LABEL')} </label>
                      <input type="text"
                             className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                             id="password-reset-username"
                             value={this.state.username}
                             onChange={ (event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ username: event.target.value }); }}
                             disabled={isDisabled}
                             required />
                  </div>
                  <div className={`invalid-password-reset-alert ${isInvalid ? 'visible' : ''}`}>
                      <WarningIcon size={24} title="Warning" disableTooltip />
                      <span>{alertMsg}</span>
                  </div>
                  <SpinnerButton className="password-reset-submit" type="submit"
                                 preOperation={this.onPreSubmit}
                                 operation={this.onSubmit}
                                 buttonLabel={Intl.get('PASSWORD_RESET_SUBMIT.BTN.TEXT')} />
              </form>
          </>
        );
    };

    public render(): React.ReactNode {
        return (
            <Scaffold header={(<Header />)} footer={(<Footer />)}>
                <main className="main-password-reset default-margins-x default-margins-y">
                    <h1> {Intl.get('PASSWORD_RESET_SUBMIT.TITLE')} </h1>
                    {this.renderAlert()}
                    {this.renderForm()}
                </main>
            </Scaffold>
        );
    }
}


export default PasswordResetSubmit;

import invariant from "invariant";
import {BrowserRouter, HashRouter} from "react-router-dom";
import {History, LocationState} from 'history';
import {IPath} from "../Paths";


let router: HashRouter|BrowserRouter|undefined;


/**
 * Stores a reference to the given router instance within the NavigationUtils module. For example,
 *
 * <pre><code>
 *     <BrowserRouter ref={router => setRouter(router)}>
 *         ...
 *     </BrowserRouter>
 * </code></pre>
 */
export function setRouter(routerInstance: HashRouter|BrowserRouter|null|undefined) {
    router = routerInstance || undefined;
}


export function getRouterHistory(): History|undefined {
    if (!router) {
        invariant(false, process.env.NODE_ENV === 'production' ? undefined :
            'Cannot get router history because no router has been set.');
        return undefined;
    }

    return (router as any).history;
}


/**
 * Allows a navigation action to be invoked on React Router from any element (even outside of the React component
 * hierarchy). Note that setRouter must be called first before this function can be used.
 *
 * @param to A string representing the path to link to.
 * @param query A string representation of query parameters.
 * @param hash A hash to put in the URL, e.g. #a-hash.
 * @param state State to persist to the location.
 */
export function navigateTo<S = LocationState>(to: string, query?: string, hash?: string, state?: S) {
    const history = getRouterHistory();
    if (!history)
        return;

    if (query || hash || state) {
        history.push({pathname: to, search: query, hash, state});

    } else {
        history.push(to);
    }
}


/**
 * Performs the same action as navigateTo, but extracts the path to link to from an IPath instance.
 *
 * @param path An IPath object containing the path to link to.
 * @param query A string representation of query parameters.
 * @param hash A hash to put in the URL, e.g. #a-hash.
 * @param state State to persist to the location.
 */
export function navigateToPath(path: IPath, query?: string, hash?: string, state?: string) {
    return navigateTo(path.path, query, hash, state);
}


/**
 * Performs a replace operation on React Router's history element that updates the query (search) parameter. Can be
 * invoked from any element (even outside of the React component hierarchy). Note that setRouter must be called first
 * before this function can be used.
 *
 * @param query A string representation of query parameters.
 */
export function replaceCurrentQuery(query?: string) {
    const history = getRouterHistory();
    if (!history)
        return;

    const location = {
        ...history.location,
        search: query
    };

    history.replace(location);
}

import * as React from 'react';
import * as Intl from "react-intl-universal";
import * as Path from './Paths';

import {setRouter} from "./util/NavigationUtils";
import {BrowserRouter, Redirect, Route, RouteComponentProps, Switch} from 'react-router-dom';

import Main from "./page/Main/Main";
import Support from "./page/Support/Support";
import PrivacyPolicy from "./page/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./page/ContactUs/ContactUs";

import {DEFAULT_LOCALE, formatLocaleWithDash, loadLocaleData, localeExists, setMomentLocale} from "./Locales";
import RequestSpinner from "./components/RequestSpinner/RequestSpinner";

import './App.scss';
import PasswordResetSubmit from "./page/PasswordReset/PasswordResetSubmit";
import PasswordResetPassword from "./page/PasswordReset/PasswordResetPassword";

class App extends React.Component<{}, {}> {

    private load = async (): Promise<any> => {
        const locales: {[key: string]: string} = {};

        locales[formatLocaleWithDash(DEFAULT_LOCALE)] = await loadLocaleData(DEFAULT_LOCALE);

        const language = "en_CA";
        const locale = localeExists(language) ? language : DEFAULT_LOCALE;
        if (locale !== DEFAULT_LOCALE) {
            locales[formatLocaleWithDash(locale)] = await loadLocaleData(locale);
        }

        await Intl.init({
            currentLocale: formatLocaleWithDash(locale),
            fallbackLocale: formatLocaleWithDash(DEFAULT_LOCALE),
            locales
        });

        setMomentLocale(locale);
    };

    public render(): React.ReactNode {
        return (
            <RequestSpinner operation={this.load}>
                <BrowserRouter ref={router => setRouter(router)}>
                    <Switch>
                        <Route {...Path.MAIN} component={Main} />
                        <Route {...Path.SUPPORT} component={Support} />
                        <Route {...Path.CONTACT_US} component={ContactUs} />
                        <Route {...Path.PRIVACY_POLICY} component={PrivacyPolicy} />
                        <Route {...Path.PASSWORD_RESET_SUBMIT} component={PasswordResetSubmit} />
                        <Route {...Path.PASSWORD_RESET_PASSWORD} render={(props: RouteComponentProps<any>) => {
                            const token = props.match.params.token;
                            return (<PasswordResetPassword token={token} />);
                        }} />
                        <Redirect {...Path.pathToLink(Path.MAIN)} />
                    </Switch>
                </BrowserRouter>
            </RequestSpinner>
        );
    }
}


export default App;

import * as React from 'react';
import * as Path from '../../Paths';
import * as Intl from 'react-intl-universal';

import logo from '../../img/AndHeartTrackLogo.svg';
import SignInModal from "../SignInModal/SignInModal";

import './Header.scss';


export interface IHeaderProps {
    tall?: boolean;
    noBrandLogo?: boolean;
}

interface IHeaderState {
    signInModalOpen: boolean;
}


class Header extends React.Component<IHeaderProps, IHeaderState> {

    constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            signInModalOpen: false
        };
    }

    private onOpenSignInModal = (): void => {
        this.setState({
            signInModalOpen: true
        });
    };

    private onCloseSignInModal = (): void => {
        this.setState({
            signInModalOpen: false
        });
    };

    public render(): React.ReactNode {
        return (
            <>
                <SignInModal isOpen={this.state.signInModalOpen} onClose={this.onCloseSignInModal} />
                <header className={`main-header default-padding-x ${this.props.tall ? 'tall' : ''}`}>
                    {!this.props.noBrandLogo && (
                        <div className="main-header-brand">
                            <a {...Path.buildLinkAttributesFromPath(Path.MAIN)}>
                                <img src={logo} alt={Intl.get('HEADER.LOGO.ALT_TEXT')} />
                            </a>
                        </div>
                    )}
                    <nav className="main-header-nav">
                        <a {...Path.buildLinkAttributesFromPath(Path.CONTACT_US)}>{Intl.get('HEADER.CONTACT_US.LINK')}</a>
                        <button className="main-header-sign-in" onClick={this.onOpenSignInModal}>{Intl.get('HEADER.SIGN_IN.BTN.TEXT')}</button>
                    </nav>
                </header>
            </>
        );
    }
}

export default Header;

import * as React from 'react';
import * as Path from '../../Paths';
import * as Intl from 'react-intl-universal';

import Modal from 'react-modal';
import {WarningIcon} from "../../icons/Icons";
import SpinnerButton from "../SpinnerButton/SpinnerButton";

import './SignInModal.scss';
import {AxiosError} from "axios";
import {postLogin} from "../../api/AuthApi";
import {parseAxiosError} from "../../api/model/SfygApiProblem";


const SIGN_IN_MODAL_MODE_IDLE = 0;
const SIGN_IN_MODAL_MODE_AUTHORIZING = 1;
const SIGN_IN_MODAL_MODE_FORM_INVALID = 2;
const SIGN_IN_MODAL_MODE_CREDENTIALS_INVALID = 3;
const SIGN_IN_MODAL_MODE_NETWORK_ERROR = 4;

type SignInModalMode = 0 | 1 | 2 | 3 | 4;


Modal.setAppElement('#root');


export interface ISignInModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface ISignInModalState {
    mode: SignInModalMode;
    username: string;
    password: string;
}


class SignInModal extends React.Component<ISignInModalProps, ISignInModalState> {

    constructor(props: ISignInModalProps) {
        super(props);
        this.state = {
            mode: SIGN_IN_MODAL_MODE_IDLE,
            username: '',
            password: ''
        };
    }

    private usernameInput: HTMLInputElement|undefined = undefined;

    private onFormSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
    };

    private onPreSubmit = (): boolean => {
        const {mode, username, password} = this.state;
        if (mode === SIGN_IN_MODAL_MODE_AUTHORIZING)
            return false;

        if (username == null || username.length === 0 || password == null || password.length === 0) {
            this.setState({ mode: SIGN_IN_MODAL_MODE_FORM_INVALID });
            return false;
        }

        return true;
    };

    private onSubmit = async (): Promise<void> => {
        this.setState({ mode: SIGN_IN_MODAL_MODE_AUTHORIZING });

        const {username, password} = this.state;
        const response = await postLogin(username, password).catch((error: AxiosError) => {

            if (error.response && error.response.status === 401) {
                this.setState({ mode: SIGN_IN_MODAL_MODE_CREDENTIALS_INVALID });
                return undefined;
            }

            const apiError = parseAxiosError(error);
            switch (apiError.sfygErrorCode) {
                case 2000: this.setState({ mode: SIGN_IN_MODAL_MODE_CREDENTIALS_INVALID }); break;
                case 2001: this.setState({ mode: SIGN_IN_MODAL_MODE_FORM_INVALID }); break;
                case 2002: this.setState({ mode: SIGN_IN_MODAL_MODE_FORM_INVALID }); break;
                default:   this.setState({ mode: SIGN_IN_MODAL_MODE_NETWORK_ERROR }); break;
            }

            return undefined;
        });

        if (response != null)
            window.location.href = "https://andhearttrack.sphygmobp.com/a/"; //response.data.redirectTo;
    };

    private onClose = (event: React.MouseEvent | React.KeyboardEvent): void => {
        if (this.state.mode === SIGN_IN_MODAL_MODE_AUTHORIZING)
            return;

        this.setState({
            mode: SIGN_IN_MODAL_MODE_IDLE,
            username: '',
            password: ''
        });

        this.props.onClose();
    };

    private onOpen = (): void => {
        if (this.usernameInput)
            this.usernameInput.focus();
    };

    private renderAlert = (): React.ReactNode => {
        const isInvalid = (this.state.mode !== SIGN_IN_MODAL_MODE_IDLE) && (this.state.mode !== SIGN_IN_MODAL_MODE_AUTHORIZING);

        let alertMsg: string = '';
        switch (this.state.mode) {
            case SIGN_IN_MODAL_MODE_FORM_INVALID:        alertMsg = Intl.get('SIGN_IN.MODE_FORM_INVALID.ERROR_MSG'); break;
            case SIGN_IN_MODAL_MODE_CREDENTIALS_INVALID: alertMsg = Intl.get('SIGN_IN.MODE_CREDENTIALS_INVALID.ERROR_MSG'); break;
            case SIGN_IN_MODAL_MODE_NETWORK_ERROR:       alertMsg = Intl.get('SIGN_IN.MODE_NETWORK_ERROR.ERROR_MSG'); break;
        }

        return (
            <div className={`invalid-sign-in-alert ${isInvalid ? 'visible' : ''}`}>
                <WarningIcon size={24} title="Warning" disableTooltip />
                <span>{alertMsg}</span>
            </div>
        );
    };

    public render(): React.ReactNode {
        const isInvalid = (this.state.mode !== SIGN_IN_MODAL_MODE_IDLE) && (this.state.mode !== SIGN_IN_MODAL_MODE_AUTHORIZING);
        const isDisabled = (this.state.mode === SIGN_IN_MODAL_MODE_AUTHORIZING);

        return (
            <Modal isOpen={this.props.isOpen}
                   className="sign-in-modal"
                   overlayClassName="sign-in-modal-overlay"
                   onAfterOpen={this.onOpen}
                   onRequestClose={this.onClose}
                   closeTimeoutMS={300}
                   shouldCloseOnOverlayClick
                   shouldCloseOnEsc>
                <form id="inputs" className="needs-validation" onSubmit={this.onFormSubmit}>
                    <div className="modal-header">
                        <h1> {Intl.get('SIGN_IN.TITLE')} </h1>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="sign-in-username"> {Intl.get('SIGN_IN.EMAIL_OR_USERNAME.LABEL')} </label>
                            <input type="text"
                                   className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                                   id="sign-in-username"
                                   ref={(e: HTMLInputElement) => { this.usernameInput = e || undefined; }}
                                   value={this.state.username}
                                   onChange={ (event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ username: event.target.value }); }}
                                   disabled={isDisabled}
                                   required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="sign-in-password"> {Intl.get('SIGN_IN.PASSWORD.LABEL')} </label>
                            <input type="password"
                                   className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
                                   id="sign-in-password"
                                   value={this.state.password}
                                   onChange={ (event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ password: event.target.value }); }}
                                   disabled={isDisabled}
                                   required />
                        </div>
                        {this.renderAlert()}
                    </div>
                    <div className="modal-footer">
                        <SpinnerButton className="sign-in-submit" type="submit" preOperation={this.onPreSubmit} operation={this.onSubmit} buttonLabel={Intl.get('SIGN_IN.BTN.LABEL')}/>
                        {/*<a className="sign-in-password-reset" {...Path.buildLinkAttributesFromPath(Path.PASSWORD_RESET_SUBMIT)}>*/}
                        {/*    {Intl.get('SIGN_IN.PASSWORD_RESET.TEXT')}*/}
                        {/*</a>*/}
                    </div>
                </form>
            </Modal>
        );
    }
}

export default SignInModal;

import * as React from 'react';
import * as Path from '../../Paths';
import * as Intl from 'react-intl-universal';

import {navigateToPath} from "../../util/NavigationUtils";

import appleAppStoreBadge from '../../img/apple-app-store-badge.svg';
import googlePlayBadge from '../../img/google-play-badge.png';
import sfygLinkingMessaging from '../../img/sfyg-linking-messaging.png';
import sfygMobileAppCombined from '../../img/sfyg-mobile-app-combined.png';
import sfygSwipeAveraging from '../../img/sfyg-swipe-averaging.png';

import Scaffold from "../../components/Scaffold/Scaffold";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Hero from "./Hero/Hero";
import Feature from "./Feature/Feature";
import SmoothScroll from "smooth-scroll";

import './Main.scss';


class Main extends React.Component<{}, {}> {

    private smoothScroll: SmoothScroll = new SmoothScroll();

    private onLearnMore = (): void => {
        const target = document.querySelector('#main-content-learn-more-target');
        this.smoothScroll.animateScroll(target);
    };

    private onContactUsCta = (): void => {
        navigateToPath(Path.CONTACT_US);
    };

    public render(): React.ReactNode {
        return (
            <Scaffold header={(<Header tall noBrandLogo />)} footer={(<Footer />)}>
                <main className="main-front-page">
                    <Hero onLearnMore={this.onLearnMore} />
                    <div id="main-content-learn-more-target" />
                    <Feature title={Intl.getHTML('MAIN.FEATURE.TITLE_1')}
                             img={sfygSwipeAveraging}
                             imgAlt="Swipe averaging">
                        <ul>
                            <li>{Intl.get('MAIN.FEATURE.LIST_1_1')}</li>
                            <li>{Intl.get('MAIN.FEATURE.LIST_1_2')}</li>
                            <li>{Intl.get('MAIN.FEATURE.LIST_1_3')}</li>
                        </ul>
                    </Feature>
                    {/*<Feature title="Caregivers"*/}
                    {/*         img={sfygSwipeAveraging}*/}
                    {/*         imgAlt="Swipe averaging">*/}
                    {/*    <p>*/}
                    {/*        The system is not only available for healthcare providers. Our system is available for*/}
                    {/*        family members to be able to keep an eye on their loved ones. It has never been more*/}
                    {/*        important for individuals and family members to be involved in healthcare. Our system makes*/}
                    {/*        it easy to track the data of family members and loved ones, no matter the distance between*/}
                    {/*        you.*/}
                    {/*    </p>*/}
                    {/*</Feature>*/}
                    <Feature title={Intl.getHTML('MAIN.FEATURE.TITLE_2')}
                             img={sfygSwipeAveraging}
                             imgAlt="Swipe averaging"
                             swapped>
                        <ul>
                            <li>{Intl.getHTML('MAIN.FEATURE.LIST_2_1')}</li>
                            <li>
                                {Intl.getHTML('MAIN.FEATURE.LIST_2_2')}
                                <ul>
                                    <li>{Intl.getHTML('MAIN.FEATURE.LIST_2_3')}</li>
                                    <li>{Intl.getHTML('MAIN.FEATURE.LIST_2_4')}</li>
                                </ul>
                            </li>
                            <li>{Intl.getHTML('MAIN.FEATURE.LIST_2_5')}</li>
                            <li>{Intl.getHTML('MAIN.FEATURE.LIST_2_6')}</li>
                        </ul>
                        {Intl.getHTML('MAIN.FEATURE.PARAGRAPH_2_1')}
                    </Feature>
                    <Feature title={Intl.getHTML('MAIN.FEATURE.TITLE_3')}
                             img={sfygLinkingMessaging}
                             imgAlt="Swipe averaging">
                        <ul>
                            <li>{Intl.get('MAIN.FEATURE.LIST_3_1')}</li>
                            <li>{Intl.get('MAIN.FEATURE.LIST_3_2')}</li>
                            <li>{Intl.get('MAIN.FEATURE.LIST_3_3')}</li>
                            <li>{Intl.get('MAIN.FEATURE.LIST_3_4')}</li>
                        </ul>
                    </Feature>
                    <Feature className="main-feature-patient-app"
                             title={Intl.getHTML('MAIN.FEATURE.TITLE_4')}
                             img={sfygMobileAppCombined}
                             imgAlt="Swipe averaging"
                             alwaysStacked>
                        {Intl.getHTML('MAIN.FEATURE.PARAGRAPH_4_1')}
                        <div className="feature-patient-app-badges">
                            <a href={Intl.get('MAIN.FEATURE.APPLE_STORE_TEXT.IMG_LINK')}
                               target="_blank" rel="noopener noreferrer">
                                <img className="apple-app-store-badge"
                                     src={appleAppStoreBadge}
                                     alt={Intl.get('MAIN.FEATURE.APPLE_STORE_TEXT.IMG_ALT')}/>
                            </a>
                            <a href={Intl.get('MAIN.FEATURE.GOOGLE_PLAY.IMG_LINK')}
                               target="_blank" rel="noopener noreferrer">
                                <img className="google-play-badge"
                                     src={googlePlayBadge}
                                     alt={Intl.get('MAIN.FEATURE.GOOGLE_PLAY.IMG_ALT')}/>
                            </a>
                        </div>
                    </Feature>
                    <div className="default-margins-x">
                        <button className="main-contact-us-btn" onClick={this.onContactUsCta}>
                            {Intl.get('MAIN.FEATURE.CONTACT_US.BTN.TEXT')}
                        </button>
                    </div>
                </main>
            </Scaffold>
        );
    }
}

export default Main;

import * as React from 'react';
import './Scaffold.scss';

export interface IScaffoldProps {
    header: React.ReactNode;
    footer: React.ReactNode;
}

class Scaffold extends React.Component<IScaffoldProps, {}> {

    public render(): React.ReactNode {
        return (
            <div className="scaffold">
                <div className="scaffold-header">
                    {this.props.header}
                </div>
                <div className="scaffold-main">
                    {this.props.children}
                </div>
                <div className="scaffold-footer">
                    {this.props.footer}
                </div>
            </div>
        );
    }
}

export default Scaffold;

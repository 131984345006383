import * as React from 'react';
import './Feature.scss';

export interface IFeatureProps {
    title: React.ReactNode;
    img: any;
    imgAlt: string;
    className?: string;
    alwaysStacked?: boolean;
    swapped?: boolean;
}

class Feature extends React.Component<IFeatureProps, {}> {

    public render(): React.ReactNode {
        const className = 'main-feature default-margins-x '
            + (this.props.className || '')
            + (this.props.swapped ? ' main-feature-swapped' : '')
            + (this.props.alwaysStacked ? ' main-feature-stacked' : '');

        return (
            <section className={className}>
                <div className="main-feature-text">
                    <h2 className="main-feature-title">
                        {this.props.title}
                    </h2>
                    <div className="main-feature-description">
                        {this.props.children}
                    </div>
                </div>
                <div className="main-feature-image">
                    <img src={this.props.img} alt={this.props.imgAlt} />
                </div>
            </section>
        );
    }
}

export default Feature;

import {ILoginRequest, ILoginResponse} from "./model/Auth";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {getAxiosInstance} from "./config/ApiConfig";
import {SERVER_DOMAIN} from "../Configuration";


export function postLogin(emailOrUsername: string, password: string): Promise<AxiosResponse<ILoginResponse>> {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url: `/api/v1/weblogin`,
        data: {
            username: emailOrUsername,
            password,
            domain: 2
        } as any as ILoginRequest
    };

    return getAxiosInstance().request(config);
}


export function postLogout(): Promise<AxiosResponse<void>> {
    const config: AxiosRequestConfig = {
        method: 'POST',
        url: `/api/v2/auth/webappLogout`
    };

    return getAxiosInstance().request(config);
}

import * as React from 'react';
import Spinner2 from "../Spinner/Spinner";
import './SpinnerButton.scss';


export interface ISpinnerButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    buttonLabel: string;
    operation: () => Promise<any>;
    preOperation?: () => boolean;
    onComplete?: () => void;
}

interface ISpinnerButtonState {
    loadState: number;
}


class SpinnerButton extends React.Component<ISpinnerButtonProps, ISpinnerButtonState> {

    private static readonly LOAD_STATE_NOT_LOADED = 0;
    private static readonly LOAD_STATE_LOADING = 1;
    private static readonly LOAD_STATE_LOADED = 2;

    private operationCancelled: boolean = false;

    private loadStateResetTimer: number|undefined = undefined;

    constructor(props: ISpinnerButtonProps) {
        super(props);
        this.state = {
            loadState: SpinnerButton.LOAD_STATE_NOT_LOADED
        };
    }

    public componentWillUnmount(): void {
        this.operationCancelled = true;

        if (this.loadStateResetTimer !== null) {
            clearTimeout(this.loadStateResetTimer);
            this.loadStateResetTimer = undefined;
        }
    }

    private onBtnClick = (): void => {
        if (this.state.loadState !== SpinnerButton.LOAD_STATE_NOT_LOADED)
            return;

        if (this.props.preOperation) {
            if (!this.props.preOperation())
                return;
        }

        this.setState({
            loadState: SpinnerButton.LOAD_STATE_LOADING
        }, () => {
            if (!this.operationCancelled)
                this.props.operation()
                    .then(this.onOperationFinished)
                    .catch(this.onOperationError)
        });

    };

    private onOperationFinished = (): void => {
        if (this.operationCancelled)
            return;

        this.setState({
            loadState: SpinnerButton.LOAD_STATE_LOADED
        }, () => {
            if (this.props.onComplete)
                this.props.onComplete();

            this.loadStateResetTimer = setInterval(this.onLoadStateReset, 1000) as any;
        });
    };

    private onOperationError = (): void => {
        if (this.operationCancelled)
            return;

        // TODO

        this.setState({
            loadState: SpinnerButton.LOAD_STATE_LOADED
        }, () => {
            this.loadStateResetTimer = setInterval(this.onLoadStateReset, 1000) as any;
        });
    };

    private onLoadStateReset = () => {
        if (this.loadStateResetTimer != null) {
            clearTimeout(this.loadStateResetTimer);
            this.loadStateResetTimer = undefined;
        }

        this.setState({
            loadState: SpinnerButton.LOAD_STATE_NOT_LOADED
        });
    };

    public render(): React.ReactNode {
        const {buttonLabel, preOperation, operation, onComplete, className, onClick, ...props} = this.props;

        let btnClass = ' sfyg-spinner-btn sfyg-spinner-btn-';
        switch (this.state.loadState) {
            case SpinnerButton.LOAD_STATE_NOT_LOADED: btnClass += 'not-loaded'; break;
            case SpinnerButton.LOAD_STATE_LOADING:    btnClass += 'loading';    break;
            case SpinnerButton.LOAD_STATE_LOADED:     btnClass += 'loaded';     break;
        }

        return (
            <button className={className + btnClass} onClick={this.onBtnClick} {...props}>
                <div className="sfyg-spinner-btn-label">
                    {buttonLabel}
                </div>
                <div className="sfyg-spinner-btn-spinner">
                    <Spinner2 />
                </div>
            </button>
        );
    }
}

export default SpinnerButton;

import * as React from "react";
import {navigateToPath} from "./util/NavigationUtils";


// region Definitions / Functions

/**
 * A path containing a "path" and "exact" field that can be passed to a Route component.
 */
export interface IPath {
    /**
     * The path to link to.
     */
    path: string;

    /**
     * Whether a URL must be an exact match to the "path" field to be considered a match.
     */
    exact?: boolean;
}

/**
 * A link containing a "to" and "exact" field that can be passed to a NavLink component.
 */
export interface ILink {
    /**
     * The path to link to.
     */
    to: string;

    /**
     * Whether a URL must be an exact match to the "to" field to be considered a match.
     */
    exact?: boolean;
}

/**
 * Converts the given path into a format that can be passed to a NavLink component.
 */
export function pathToLink(path: IPath, replaceDictionary?: {[search: string]: string}): ILink {
    const path2 = (!!replaceDictionary) ? pathReplace(path, replaceDictionary) : path;

    return {
        to: path2.path,
        exact: path.exact,
    };
}

/**
 * Returns a new IPath instance with a string replace operation applied to the "path" field of the given IPath.
 * @param path The IPath instance to perform the replace on.
 * @param replaceDictionary A dictionary mapping placeholders (colon prefix will be appended) to their replacements.
 */
export function pathReplace(path: IPath, replaceDictionary: {[search: string]: string}): IPath {
    let pathValue = path.path;

    Object.keys(replaceDictionary).forEach((search: string) => {
        pathValue = pathValue.replace(':' + search, replaceDictionary[search]);
    });

    return {
        path: pathValue,
        exact: path.exact
    };
}

export function buildLinkAttributesFromPath(path: IPath, query?: string, replaceDictionary?: {[search: string]: string}) {
    const path2 = (!!replaceDictionary) ? pathReplace(path, replaceDictionary) : path;

    return {
        href: path2.path,
        onClick: (event: React.MouseEvent<{}, MouseEvent>): void => {
            event.preventDefault();
            navigateToPath(path2, query);
        }
    };
}

// endregion


export const MAIN: IPath = {path: '/', exact: true};

export const SUPPORT: IPath = {path: '/p/support', exact: true};

export const PRIVACY_POLICY: IPath = {path: '/p/privacyPolicy', exact: true};

export const CONTACT_US: IPath = {path: '/p/contactUs', exact: true};

export const PASSWORD_RESET_SUBMIT: IPath = {path: '/p/passwordReset', exact: true};

export const PASSWORD_RESET_PASSWORD: IPath = {path: '/p/passwordReset/:token', exact: true};
